import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`

const Scoring = (props) => {

  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postJobParsingCopilot={props.postJobParsingCopilot}
        params={props.queryParams}
        jobParsingCopilot={props.jobParsingCopilot}
        recruiterCopilot={props.recruiterCopilot}
      />
      {props.queryParams?.EntityID ?
        (
          <Result 
            email={props.queryParams.email}
            token={props.queryParams.token}
            reference={props.queryParams.EntityID}
            getRecruiterCopilot={props.getRecruiterCopilot}
          />
        )
        :
        (
          ""
        )
      }
    </StyledContainer>
  )
}


export default Scoring