import React from "react"
import PropTypes from "prop-types"

const Envolope = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="26px"
      height="20px"
      viewBox="0 0 26 20"
    >
      <path d="M2.99976 0H22.9998C23.6628 0 24.2987 0.263392 24.7675 0.732233C25.2364 1.20107 25.4998 1.83696 25.4998 2.5V17.5C25.4998 18.163 25.2364 18.7989 24.7675 19.2678C24.2987 19.7366 23.6628 20 22.9998 20H2.99976C2.33671 20 1.70083 19.7366 1.23199 19.2678C0.763148 18.7989 0.499756 18.163 0.499756 17.5V2.5C0.499756 1.125 1.62476 0 2.99976 0ZM22.9998 4.225V2.5H2.99976V4.225L12.9998 9.225L22.9998 4.225ZM22.9998 7.025L13.5623 11.7375C13.3878 11.8254 13.1951 11.8712 12.9998 11.8712C12.8044 11.8712 12.6117 11.8254 12.4373 11.7375L2.99976 7.025V17.5H22.9998V7.025Z" fill={color}/>
    </svg>
  )
}

Envolope.propTypes = {
  color: PropTypes.string,
}

Envolope.defaultProps = {
  color: "#000000",
}

export default Envolope
