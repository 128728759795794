import React from 'react'
import styled from "styled-components"

const StyledDiv = styled.div`
  margin-top: 3rem;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 10px;
  color: rgba(100, 133, 168, 0.8);
  text-align: left;
  a {
    color: rgb(52, 203, 230);
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    cursor: pointer;
  }
`
export default function Cgu() {
  return (
    <StyledDiv>
      By clicking on "Analyze", I agree to the&nbsp;	
      <a target='_blank' href="https://help.hrflow.ai/en/articles/4418910-privacy-policy">Terms of Service and Privacy policy</a> 
    </StyledDiv>
  )
}
