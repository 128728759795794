import React from 'react'
import styled from 'styled-components';

const InputElement = styled.input`
  outline: none;
  border: 1px solid #C6D7EA;
  border-radius: 0.5rem;
  background-color: white;
  padding: 0.9rem 1.3rem 0.81rem 1.3rem;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colors.tertiary};
  &::placeholder {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  &:focus {
      outline: none;
      background-color: #eee;
  }
`

const TextareaElement = styled(InputElement)`
    min-height: 10rem;
    padding-right: 2rem;
`

const Input = (props) => {
  if(props.type === 'input') {
    return (
      <InputElement
        onChange={props.changed}
        placeholder={props.placeholder}
        required
        type={props.content === "text" ? "input" : "email"}
        value={props.value}
      />
    )
  }

  if(props.type === 'textarea') {
    return (
        <TextareaElement
          as="textarea"
          onChange={props.changed}
          placeholder={props.placeholder}
          required
          value={props.value}
        />
    )
  }
}

export default Input;