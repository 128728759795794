import * as actionTypes from './types'
import axiosHrflow from 'utils/axiosHrflow'
import axiosWorkflow from 'utils/axiosWorkflow'
import { buildQueryString, SOURCE_KEY } from 'utils'

export const postProfileFileRequest = () => {
    return {
        type: actionTypes.POST_PROFILE_FILE_REQUEST,
    }
}

export const postProfileFileSuccess = (res) => {
    return {
        type: actionTypes.POST_PROFILE_FILE_SUCCESS,
        res,
    }
}

export const postProfileFileFail = (error) => {
    return {
        type: actionTypes.POST_PROFILE_FILE_FAIL,
        error,
    }
}

export const getProfileIndexingRequest = () => {
    return {
        type: actionTypes.GET_PROFILE_INDEXING_REQUEST,
    }
}

export const getProfileIndexingSuccess = (profileIndexing) => {
    return {
        type: actionTypes.GET_PROFILE_INDEXING_SUCCESS,
        profileIndexing,
    }
}

export const getProfileIndexingFail = (error) => {
    return {
        type: actionTypes.GET_PROFILE_INDEXING_FAIL,
        error,
    }
}

export const getProfileSearchingRequest = () => {
    return {
        type: actionTypes.GET_PROFILE_SEARCHING_REQUEST,
    }
}

export const getProfileSearchingSuccess = (profiles) => {
    return {
        type: actionTypes.GET_PROFILE_SEARCHING_SUCCESS,
        profiles,
    }
}

export const getProfileSearchingFail = () => {
    return {
        type: actionTypes.GET_PROFILE_SEARCHING_FAIL,
    }
}

export const getRecruiterCopilotRequest = () => {
    return {
        type: actionTypes.GET_RECRUITER_COPILOT_REQUEST,
    }
}

export const getRecruiterCopilotSuccess = (res) => {
    return {
        type: actionTypes.GET_RECRUITER_COPILOT_SUCCESS,
        res,
    }
}

export const getRecruiterCopilotFail = () => {
    return {
        type: actionTypes.GET_RECRUITER_COPILOT_FAIL,
    }
}

export const postProfileAskingRequest = () => {
    return {
        type: actionTypes.POST_PROFILE_ASKING_REQUEST,
    }
}

export const postProfileAskingSuccess = (res) => {
    return {
        type: actionTypes.POST_PROFILE_ASKING_SUCCESS,
        res,
    }
}

export const postProfileAskingFail = (error) => {
    return {
        type: actionTypes.POST_PROFILE_ASKING_FAIL,
        error,
    }
}

export const getProfileAskingRequest = () => {
    return {
        type: actionTypes.GET_PROFILE_ASKING_REQUEST,
    }
}

export const getProfileAskingSuccess = (res) => {
    return {
        type: actionTypes.GET_PROFILE_ASKING_SUCCESS,
        res,
    }
}

export const getProfileAskingFail = (error) => {
    return {
        type: actionTypes.GET_PROFILE_ASKING_FAIL,
        error,
    }
}

export const postProfileUnfoldingRequest = () => {
    return {
        type: actionTypes.POST_PROFILE_UNFOLDING_REQUEST,
    }
}

export const postProfileUnfoldingSuccess = (res) => {
    return {
        type: actionTypes.POST_PROFILE_UNFOLDING_SUCCESS,
        res,
    }
}

export const postProfileUnfoldingFail = (error) => {
    return {
        type: actionTypes.POST_PROFILE_UNFOLDING_FAIL,
        error,
    }
}


export const getProfileUnfoldingRequest = () => {
    return {
        type: actionTypes.GET_PROFILE_UNFOLDING_REQUEST,
    }
}

export const getProfileUnfoldingSuccess = (profileUnfolding) => {
    return {
        type: actionTypes.GET_PROFILE_UNFOLDING_SUCCESS,
        profileUnfolding,
    }
}

export const getProfileUnfoldingFail = (error) => {
    return {
        type: actionTypes.GET_PROFILE_UNFOLDING_FAIL,
        error,
    }
}



export const getProfileSearching = payload => {
    return dispatch => {
        dispatch(getProfileSearchingRequest())
        const query = {
            board_key: "",
            job_key: "",
            source_keys: [SOURCE_KEY],
            stage: 'new',
            sort_by: 'created_at',
            order_by: 'desc',
            limit: 10,
            page: 1,
            location_distance: 30,
            location_geopoint: payload.location,
            use_agent: 0,
            text_keywords: [],
            tags_included: payload.tags,
            // tags: payload.tags
        }
        const url = buildQueryString('profiles/searching', query)
        axiosHrflow.get(url)
            .then( res => {
                const fetchedProfiles = {profiles: res.data.data.profiles, meta: res.data.meta}
                dispatch(getProfileSearchingSuccess(fetchedProfiles))
            } )
            .catch( err => {
                dispatch(getProfileSearchingFail(err))
            } )
    }
}


export const postProfileFile = (payload) => {
    return dispatch => {
        dispatch(postProfileFileRequest())
        let workflowKey = '1cc7c7613542f272396028c5cb66cd3fb8ac2254'
        let successEvent = "POST_PROFILE_PARSING_SUCCESS"
        let failEvent = "POST_PROFILE_PARSING_FAIL"

        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}
        const fd = new FormData()
        fd.append('file', payload.file)
        fd.append('email', payload.email)
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData)
        if(payload.board) {
            fd.append('board', payload.board)
            workflowKey = "cce1e4a176de65e6ec15a34402b188493e9b8c05"
            successEvent = "TALENT_COPILOT_SUCCESS"
            failEvent = "TALENT_COPILOT_FAIL"
        }
        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/${workflowKey}`, fd)
            .then( res => {
                const json  = JSON.parse(res.data.stdout)
                if(json === 201) {
                    dispatch(postProfileFileSuccess(res));
                    window.analytics.track(successEvent, {
                        email: payload.email,
                        type: 'profile-parsing',
                        marketing_data: marketingData,
                    });
                    window.analytics.identify(payload.email, {
                        email: payload.email,
                        event: successEvent,
                        marketing_data: marketingData,
                    });
                } else {
                    dispatch(postProfileFileFail(res));
                    window.analytics.track(failEvent, {
                        email: payload.email,
                        type: 'profile-parsing',
                        marketing_data: marketingData,
                    });
                }
            } )
            .catch( err => {
                dispatch(postProfileFileFail(err));
                window.analytics.track(failEvent, {
                    email: payload.email,
                    type: 'profile-parsing',
                    marketing_data: marketingData,
                })
            } )
    }
}

export const getProfileIndexing = (payload) => {
    return dispatch => {
        dispatch(getProfileIndexingRequest())
        const fd = new FormData()
        fd.append('profile_key', payload.profile_key)
        fd.append('email', payload.email)
        axiosWorkflow.post( '/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/df0055777497a3c5db7f7b4d4aa0cc09ac037aa1', fd)
            .then( res => {
                const json  = res.data.response
                dispatch(getProfileIndexingSuccess(json.data))
                
                window.analytics.track("GET_PROFILE_INDEXING_SUCCESS", {
                    email: payload.email,
                    type: 'profile-parsing'
                })
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(getProfileIndexingFail(err))
                window.analytics.track("GET_PROFILE_INDEXING_FAIL", {
                    email: payload.email,
                    type: 'profile-parsing'
                })
            } )
    }
}

export const getRecruiterCopilot = (payload) => {
    return dispatch => {
        dispatch(getRecruiterCopilotRequest())
        const fd = new FormData()
        fd.append('reference', payload.reference)
        fd.append('email', payload.email)
        axiosWorkflow.post('/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.9/2b12772a1bb51dae885db26fcc505d038f4074df', fd)
            .then( res => {

                const json  = res.data.response
                dispatch(getRecruiterCopilotSuccess(json.data))
                
                window.analytics.track("GET_RECRUITER_COPILOT_SUCCESS", {
                    email: payload.email,
                    type: 'recruiter-copilot'
                })
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(getRecruiterCopilotFail(err))
                window.analytics.track("GET_RECRUITER_COPILOT_FAIL", {
                    email: payload.email,
                    type: 'recruiter-copilot'
                })
            } )
    }
}

export const postProfileUnfolding = (payload) => {
    return dispatch => {
        dispatch(postProfileUnfoldingRequest())
        let workflowKey = '2f820d0b7acdc77c7b6b46c0a34c96192e610213'
        let successEvent = "POST_PROFILE_UNFOLDING_SUCCESS"
        let failEvent = "POST_PROFILE_UNFOLDING_FAIL"

        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}
        const fd = new FormData()
        fd.append('file', payload.file)
        fd.append('file_name', payload.file_name)
        fd.append('file_size', payload.file_size)
        fd.append('email', payload.email)
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData)

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/${workflowKey}`, fd)
            .then( res => {
                const json  = res.data.response
                dispatch(postProfileUnfoldingSuccess(json.data))
                
                window.analytics.track(successEvent, {
                    email: payload.email,
                    type: 'profile-unfolding',
                    marketing_data: marketingData,
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: successEvent,
                    marketing_data: marketingData,
                });
            } )
            .catch( err => {
                dispatch(postProfileUnfoldingSuccess(err));
                window.analytics.track(failEvent, {
                    email: payload.email,
                    type: 'profile-unfolding',
                    marketing_data: marketingData,
                })
            } )
    }
}

export const getProfileUnfolding = (payload) => {
    return dispatch => {
        dispatch(getProfileUnfoldingRequest())
        let workflowKey = 'e1f52c8817c8343aa65a0d6810f8dbe1ee3c766e'
        let successEvent = "GET_PROFILE_UNFOLDING_SUCCESS"
        let failEvent = "GET_PROFILE_UNFOLDING_FAIL"

        const fd = new FormData()
        fd.append('profile_key', payload.profile_key)
        fd.append('email', payload.email)
        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/${workflowKey}`, fd)
            .then( res => {
                const json  = res.data.response
                dispatch(getProfileUnfoldingSuccess(json.data))
                
                window.analytics.track(successEvent, {
                    email: payload.email,
                    type: 'profile-unfolding'
                })
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(getProfileUnfoldingFail(err))
                window.analytics.track(failEvent, {
                    email: payload.email,
                    type: 'profile-unfolding'
                })
            } )
    }
}

export const postProfileAsking = (payload) => {
    return dispatch => {
        dispatch(postProfileAskingRequest())
        let workflowKey = '263e1fe8da0b9a8990f2f9c7e1b9cfc91ec2043c'
        let successEvent = "POST_PROFILE_ASKING_SUCCESS"
        let failEvent = "POST_PROFILE_ASKING_FAIL"

        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}
        const fd = new FormData()
        fd.append('file', payload.file);
        fd.append('email', payload.email);
        fd.append('questions', JSON.stringify(payload.questions));
        fd.append('profile_file', JSON.stringify(payload.profile_file));
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/${workflowKey}`, fd)
            .then( res => {
                const json  = JSON.parse(res.data.stdout)
                if(json === 201) {
                    dispatch(postProfileAskingSuccess(res));
                    window.analytics.track(successEvent, {
                        email: payload.email,
                        type: 'profile-asking',
                        marketing_data: marketingData,
                    });
                    window.analytics.identify(payload.email, {
                        email: payload.email,
                        event: successEvent,
                        marketing_data: marketingData,
                    });
                } else {
                    dispatch(postProfileAskingFail(res));
                    window.analytics.track(failEvent, {
                        email: payload.email,
                        type: 'profile-asking',
                        marketing_data: marketingData,
                    });
                }
            } )
            .catch( err => {
                dispatch(postProfileAskingFail(err));
                window.analytics.track(failEvent, {
                    email: payload.email,
                    type: 'profile-asking',
                    marketing_data: marketingData,
                })
            } )
    }
}

export const getProfileAsking = (payload) => {
    return dispatch => {
        dispatch(getProfileAskingRequest())
        const fd = new FormData()
        fd.append('email', payload.email)
        fd.append('profile_key', payload.key)

        axiosWorkflow.post( '/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/eff2ca710c053b5ff40c51dcbdfe62ea65d00c3d', fd)
            .then( res => {
                const json  = res.data
                dispatch(getProfileAskingSuccess(json))
                
                window.analytics.track("GET_PROFILE_ASKING_SUCCESS", {
                    email: payload.email,
                    type: 'profile-asking'
                })
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(getProfileAskingFail(err))
                window.analytics.track("GET_PROFILE_ASKING_FAIL", {
                    email: payload.email,
                    type: 'profile-asking'
                })
            } )
    }
}