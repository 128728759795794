import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { getRecruiterCopilot, } from "store/actions/profile"
import { postJobParsingCopilot } from "store/actions/job"

import { getSearchParams } from "gatsby-query-params";

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import Grid from "components/sections/grid"
import Breadcumbs from "components/common/breadcumbs"

import { seoTitleSuffix } from "config"
import Scoring from "components/profile/scoring"
import peopleImg from "content/images/people.svg"
import PrimaryCard from "components/common/primaryCard"
import ProfileAskingImg from "components/assets/profile-asking.png"
import ProfileUnfoldingImg from "components/assets/profile-unfolding.png";


const profileSearching = "https://hrflow-ai.imgix.net/labs/profile-searching.png?w=350&h=350&fit=clip"
const resumeParsing = "https://hrflow-ai.imgix.net/labs/resume-parsing.png?w=350&h=350&fit=clip"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`

const ProfileScoringPage = (
  {
    data,
    getRecruiterCopilot,
    recruiterCopilot,
    postJobParsingCopilot,
    jobParsingCopilot
  }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter
  const queryParams = getSearchParams()

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#22B5F3"
          text="Profile analysis"
          link="/profile-analysis/"
          logo={peopleImg}
        />
        <Hero
          content={data.hero.edges}
          logo={peopleImg}
          color="#22B5F3"
          resultPage={queryParams?.token}
          docs="https://developers.hrflow.ai/reference/score-profiles-for-a-job"
        />
        <Grid form>
          <Scoring
            getRecruiterCopilot={getRecruiterCopilot}
            recruiterCopilot={recruiterCopilot}
            postJobParsingCopilot={postJobParsingCopilot}
            jobParsingCopilot={jobParsingCopilot}
            queryParams={queryParams}
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
        <StyledGrid className="mt-2">
          <PrimaryCard
            image={resumeParsing}
            color="#22B5F3"
            title="Resume parsing"
            link="/profile-analysis/parsing/"
            subtitle="Upload a resume/CV extract its semantic entities — such as names, emails, phones, locations, companies, schools, degrees, job titles, tasks, skills, interests, durations, dates and more."
          />  
          <PrimaryCard
            image={profileSearching}
            color="#22B5F3"
            title="Profile Searching"
            link="/profile-analysis/searching/"
            codeUrl="https://codepen.io/HrFlow/pen/eYdOjPP"
            subtitle="Talent Pool/Marketplace Search Demo powered by HrFlow.ai Profile Searching API."
          />
          <PrimaryCard
            image={ProfileAskingImg}
            color="#22B5F3"
            title="Profile Asking"
            link="/profile-analysis/asking/"
            subtitle="Ask a question based on a Profile object with HrFlow.ai Profile Asking API."
          />
          <PrimaryCard
            image={ProfileUnfoldingImg}
            color="#22B5F3"
            title="Profile Unfolding"
            link="/profile-analysis/unfolding/"
            subtitle="Predict the future experiences and educations of a profile. with HrFlow.ai Profile Unfolding API."
          />    
        </StyledGrid>
        </Demos>
      </Layout>
    </GlobalStateProvider>
  )
}

ProfileScoringPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  recruiterCopilot: state.profile.recruiterCopilot,
  jobParsingCopilot: state.job.jobParsingCopilot,
})

export default connect(mapStateToProps, { getRecruiterCopilot, postJobParsingCopilot })(ProfileScoringPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/profile/scoring/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/profile/scoring/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/profile/scoring/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`