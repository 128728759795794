import * as actionTypes from './types'
import { buildQueryString, BOARD_KEY } from 'utils'
import axiosHrflow from 'utils/axiosHrflow'
import axiosWorkflow from 'utils/axiosWorkflow'

export const postJobParsingRequest = () => {
    return {
        type: actionTypes.POST_JOB_PARSING_REQUEST,
    };
};

export const postJobParsingSuccess = (res) => {
    return {
        type: actionTypes.POST_JOB_PARSING_SUCCESS,
        res,
    };
};

export const postJobParsingFail = (error) => {
    return {
        type: actionTypes.POST_JOB_PARSING_FAIL,
        error: error
    };
};

export const getJobParsingRequest = () => {
    return {
        type: actionTypes.GET_JOB_PARSING_REQUEST,
    };
};

export const getJobParsingSuccess = (res) => {
    return {
        type: actionTypes.GET_JOB_PARSING_SUCCESS,
        res,
    };
};

export const getJobParsingFail = (error) => {
    return {
        type: actionTypes.GET_JOB_PARSING_FAIL,
        error: error
    };
};

export const postJobRevealingRequest = () => {
    return {
        type: actionTypes.POST_JOB_REVEALING_REQUEST,
    };
};

export const postJobRevealingSuccess = (res) => {
    return {
        type: actionTypes.POST_JOB_REVEALING_SUCCESS,
        res,
    };
};

export const postJobRevealingFail = (error) => {
    return {
        type: actionTypes.POST_JOB_REVEALING_FAIL,
        error: error
    };
};

export const getJobRevealingRequest = () => {
    return {
        type: actionTypes.GET_JOB_REVEALING_REQUEST,
    };
};

export const getJobRevealingSuccess = (res) => {
    return {
        type: actionTypes.GET_JOB_REVEALING_SUCCESS,
        res,
    };
};

export const getJobRevealingFail = (error) => {
    return {
        type: actionTypes.GET_JOB_REVEALING_FAIL,
        error: error
    };
};

export const fetchJobsRequest = () => {
    return {
        type: actionTypes.FETCH_JOBS_REQUEST,
    };
};

export const fetchJobsSuccess = (jobs) => {
    return {
        type: actionTypes.FETCH_JOBS_SUCCESS,
        jobs,
    };
};

export const fetchJobsFail = () => {
    return {
        type: actionTypes.FETCH_JOBS_FAIL,
    };
};

export const postJobParsingCopilotRequest = () => {
    return {
        type: actionTypes.POST_JOB_PARSING_COPILOT_REQUEST,
    };
};

export const postJobParsingCopilotSuccess = (res) => {
    return {
        type: actionTypes.POST_JOB_PARSING_COPILOT_SUCCESS,
        res,
    };
};

export const postJobParsingCopilotFail = (error) => {
    return {
        type: actionTypes.POST_JOB_PARSING_COPILOT_FAIL,
        error: error
    };
};

export const postJobAskingRequest = () => {
    return {
        type: actionTypes.POST_JOB_ASKING_REQUEST,
    };
};

export const postJobAskingSuccess = (res) => {
    return {
        type: actionTypes.POST_JOB_ASKING_SUCCESS,
        res,
    };
};

export const postJobAskingFail = (error) => {
    return {
        type: actionTypes.POST_JOB_ASKING_FAIL,
        error: error
    };
};

export const getJobAskingRequest = () => {
    return {
        type: actionTypes.GET_JOB_ASKING_REQUEST,
    };
};

export const getJobAskingSuccess = (res) => {
    return {
        type: actionTypes.GET_JOB_ASKING_SUCCESS,
        res,
    };
};

export const getJobAskingFail = (error) => {
    return {
        type: actionTypes.GET_JOB_ASKING_FAIL,
        error: error
    };
};


export const fetchJobs = (payload) => {
    return dispatch => {
        dispatch(fetchJobsRequest());
        const query = {
            board_keys: [BOARD_KEY],
            // agent_key: AGENT_KEY,
            tags_included: [[], []],
            names: payload.title ? [encodeURIComponent(payload.title)] : [],
            limit: 10,
            page: 1,
            sort_by: 'searching',
            order_by: 'asc',
            location_distance: payload.location ? 30 : null,
            location_geopoint: payload.location,
            use_agent: 0,
            profile_key: '',
            // source_key: SOURCE_KEY,
            skills: payload.skills.map(skill => {
                return (
                    {name: encodeURIComponent(skill.name), value: skill.value }
                )
            }),
            totalPage : 0,
            status: true,
        }
        const url = buildQueryString('jobs/searching', query)
        axiosHrflow.get(url)
            .then( res => {
                const fetchedJobs = {jobs: res.data.data.jobs, meta: res.data.meta}
                dispatch(fetchJobsSuccess(fetchedJobs));
            } )
            .catch( err => {
                dispatch(fetchJobsFail(err));
            } );
    };
};

export const postJobParsing = (payload) => {
    return dispatch => {
        dispatch(postJobParsingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('description', payload.description);
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);
        axiosWorkflow.post( `786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/79239472c8c5fb6890336c2697e523cdf668e86c`, fd)
            .then( res => {
                dispatch(postJobParsingSuccess(res));
                window.analytics.track("POST_TEXT_PARSING_SUCCESS", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_TEXT_PARSING_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                dispatch(postJobParsingFail(err));
                window.analytics.track("POST_TEXT_PARSING_FAIL", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
            } );
    };
};

export const postJobAsking = (payload) => {
    return dispatch => {
        dispatch(postJobAskingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('title', payload.title);
        fd.append('description', payload.description);
        fd.append('questions', JSON.stringify(payload.questions));
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);
        axiosWorkflow.post( `786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/4f9301290a76c50c2d23ef5f5c4365840219654f`, fd)
            .then( res => {
                dispatch(postJobAskingSuccess(res));
                window.analytics.track("POST_JOB_ASKING_SUCCESS", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_JOB_ASKING_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                dispatch(postJobAskingFail(err));
                window.analytics.track("POST_JOB_ASKING_FAIL", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
            } );
    };
};

export const getJobAsking = (payload) => {
    return dispatch => {
        dispatch(getJobAskingRequest())
        let workflowKey = 'a5dc7c591c55127a43c58f72bf2296a218c473b5'
        let successEvent = "GET_JOB_ASKING_SUCCESS"
        let failEvent = "GET_JOB_ASKING_FAIL"

        const fd = new FormData()
        fd.append('email', payload.email);
        fd.append('key', payload.key);

        axiosWorkflow.post(`/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/${workflowKey}`, fd)
            .then( res => {
                const json  = res.data
                dispatch(getJobAskingSuccess(json))

                window.analytics.track(successEvent, {
                    email: payload.email,
                    type: 'job-asking'
                })
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(getJobAskingFail(err))
                window.analytics.track(failEvent, {
                    email: payload.email,
                    type: 'job-asking'
                })
            } )
    }
}


export const getJobParsing = (payload) => {
    return dispatch => {
        dispatch(getJobParsingRequest());
        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('job_key', payload.jobKey);
        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/e3e86d08d88ed33e5803a054fea935d81e020c76`, fd)
            .then( res => {
                const json  = JSON.parse(res.data.stdout)
                dispatch(getJobParsingSuccess(json.data));
                window.analytics.track("GET_JOB_PARSING_SUCCESS", {
                    email: payload.email,
                    type: 'job-parsing'
                });
            } )
            .catch( err => {
                dispatch(getJobParsingFail(err));
                window.analytics.track("GET_JOB_PARSING_FAIL", {
                    email: payload.email,
                    type: 'job-parsing'
                });
            } );
    };
};

export const postJobRevealing = (payload) => {
    return dispatch => {
        dispatch(postJobRevealingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('description', payload.description);
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/c023a40c9f22c616aacaed5b7f6b9c2901be280a`, fd)
            .then( res => {
                dispatch(postJobRevealingSuccess(res));
                console.log('res', res)
                window.analytics.track("POST_TEXT_REVEALING_SUCCESS", {
                    email: payload.email,
                    type: 'job-revealing',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_TEXT_REVEALING_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(postJobRevealingFail(err));
                window.analytics.track("POST_TEXT_REVEALING_FAIL", {
                    email: payload.email,
                    type: 'job-revealing',
                    marketing_data: marketingData
                });
            } );
    };
};


export const getJobRevealing = (payload) => {
    return dispatch => {
        dispatch(getJobRevealingRequest());
        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('job_key', payload.jobKey);
        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/5700769a7724f368307b11385065862fb6a58e76`, fd)
            .then( res => {
                const json  = {
                    data: JSON.parse(JSON.parse(res.data.stdout).result).data ,
                    text: JSON.parse(res.data.stdout).text
                }
                dispatch(getJobRevealingSuccess(json));
                window.analytics.track("GET_JOB_REVEALING_SUCCESS", {
                    email: payload.email,
                    type: 'job-revealing'
                });
            } )
            .catch( err => {
                dispatch(getJobRevealingFail(err));
                window.analytics.track("JOB_REVEALING_FAIL", {
                    email: payload.email,
                    type: 'job-revealing'
                });
            } );
    };
};


export const postJobParsingCopilot = (payload) => {
    return dispatch => {
        dispatch(postJobParsingCopilotRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('description', payload.description);
        fd.append('title', payload.title);
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);
        axiosWorkflow.post( `786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/a9878de2cca7ffc1c9b24f68fb8dfde7998c811b`, fd)
            .then( res => {
                console.log('res', res)
                dispatch(postJobParsingCopilotSuccess(res));
                window.analytics.track("POST_JOB_PARSING_COPILOT_SUCCESS", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_JOB_PARSING_COPILOT_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                dispatch(postJobParsingCopilotFail(err));
                window.analytics.track("POST_JOB_PARSING_COPILOT_FAIL", {
                    email: payload.email,
                    type: 'text-parsing',
                    marketing_data: marketingData
                });
            } );
    };
};